import { gql } from 'urql'
import { CustomFieldResponseItem } from '../types/custom-field'

const QueryAgencyCustomFieldSettingByUser = gql<
  {
    customFieldSettingsByUserList: {
      collection: Array<CustomFieldResponseItem>
      metadata: {
        totalCount: number
      }
    }
  },
  {
    limit?: number
    page?: number
    objectKind: string
  }
>`
  query ($limit: Int, $page: Int, $objectKind: HfAgencyCustomFieldObjectKind!) {
    customFieldSettingsByUserList(
      limit: $limit
      page: $page
      objectKind: $objectKind
    ) {
      collection {
        id
        fieldName
        objectKind
        fieldKind
        index
        roleIds
        selectOptions
        visibility
        clientUserVisibility
        careerSiteVisibility
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryAgencyCustomFieldSettingByUser
