import { gql } from 'urql'
import { SourceListType } from '../types'

export type ProfileSourceListQuery = {
  profileSourcesList: {
    collection: SourceListType[]
    metadata: { totalCount: number }
  }
}

export const QueryProfileSourceList = gql<
  ProfileSourceListQuery,
  {
    page?: number
    limit?: number
    search?: string
    sourced?: string
  }
>`
  query ($page: Int, $limit: Int, $search: String, $sourced: String) {
    profileSourcesList(
      page: $page
      limit: $limit
      search: $search
      sourced: $sourced
    ) {
      collection {
        id
        key
        name
      }
      metadata {
        totalCount
      }
    }
  }
`
