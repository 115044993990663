import { ISelectOption } from '~/core/ui/Select'
import { CustomFieldViewType } from '../../../profile-fields/types/custom-field'
import { IResume, ISectionCustomFieldParamType } from '../types'
import { LIST_SECTIONS_FIELD_DEFAULT } from './enum'

export const getFormatDegreeEducation = (item?: {
  degree?: string
  degreeSubject?: string
  listDegrees?: ISelectOption[]
}) => {
  if (!item) return ''

  let arr = []

  if (item?.degree) {
    arr.push(
      (item?.listDegrees || [])?.filter(
        (s: ISelectOption) => s.value === item.degree
      )?.[0]?.supportingObj?.name
    )
  }

  if (item?.degreeSubject) {
    arr.push(item.degreeSubject)
  }

  return arr.join(' of ')
}

export const removeHTMLTags = (content: string): string => {
  return content.replace(/(<([^>]+)>)/gi, '')
}

export const calculateSize = (
  num: number,
  percentScale: number,
  unit = 'px'
) => {
  return num + (num / 100) * percentScale + unit
}

export const checkShowSectionName = ({
  section,
  resumeData,
  mappingsField
}: {
  section: ISectionCustomFieldParamType
  resumeData?: IResume
  mappingsField: CustomFieldViewType[]
}) => {
  let show = false
  const cvTemplateCustomFields = section.cvTemplateCustomFields || []

  for (let i = 0; i < cvTemplateCustomFields?.length; i++) {
    const field = cvTemplateCustomFields?.[i]
    const key = field.key

    if (field?.isCustom) {
      const customField = mappingsField.find(
        (item) => String(item.id) === String(field.customSettingId)
      )

      if (customField) show = true
    } else {
      if (key === LIST_SECTIONS_FIELD_DEFAULT.fullName) {
        if (resumeData?.permittedFields?.fullName) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.email) {
        if (resumeData?.permittedFields?.email) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.phoneNumber) {
        if (resumeData?.permittedFields?.phoneNumber) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.location) {
        if (resumeData?.permittedFields?.location) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.links) {
        if (resumeData?.permittedFields?.links) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.headline) {
        if (resumeData?.permittedFields?.headline) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.totalYearsOfExp) {
        if (resumeData?.permittedFields?.totalYearsOfExp) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.skills) {
        if (resumeData?.permittedFields?.skills) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.languages) {
        if (resumeData?.permittedFields?.languages) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.currentSalary) {
        if (resumeData?.permittedFields?.currentSalary) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.expectedSalary) {
        if (resumeData?.permittedFields?.expectedSalary) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.birthday) {
        if (resumeData?.permittedFields?.birthday) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.profileLevel) {
        if (resumeData?.permittedFields?.profileLevel) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.nationality) {
        if (resumeData?.permittedFields?.nationality) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.willingToRelocate) {
        if (resumeData?.permittedFields?.willingToRelocate) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.noticeToPeriodDays) {
        if (resumeData?.permittedFields?.noticeToPeriodDays) show = true
      }

      if (key === LIST_SECTIONS_FIELD_DEFAULT.talentPools) {
        if (resumeData?.permittedFields?.talentPools) show = true
      }
    }
  }

  return show
}

export const toDataURL = (url: string) =>
  fetch(url, { signal: AbortSignal.timeout(5000) })
    .then((response) => {
      if (response.ok) {
        return response.blob()
      }

      return { type: 'error' }
    })
    .then((blob) => {
      if (blob?.type !== 'error') {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          // @ts-expect-error
          reader.readAsDataURL(blob)
        })
      }

      return ''
    })
    .catch(() => '')
