import { differenceInYears } from 'date-fns'
import locale from 'date-fns/locale/en-US'
import { TFunction } from 'next-i18next'
import { IRole, IUserInformation } from '~/core/@types/global'
import { ROLE } from '~/core/constants/role'
import { defaultFormatDate } from '~/core/utilities/format-date'
import { adminCanAction } from '~/core/utilities/permission'
import { InterviewDetailType } from '../../calendar/types'
import { IMemberInfo } from '../../jobs/graphql/query-job-detail-mini'
import { ICandidateApplicant } from '../types'
import {
  FILTER_CANDIDATE_FIELDS_CONDITION,
  FILTER_CANDIDATE_FIELDS_VALUE
} from './enum'

export const formatProfileApplicantDate = (
  item: ICandidateApplicant,
  t: TFunction
) => {
  if (!item.createdAt) return ''

  return `${
    item.incoming === 'career_page'
      ? `${t('label:jobCardLabel:applied')}`
      : `${t('label:jobCardLabel:sourced')}`
  } ${defaultFormatDate(new Date(item?.createdAt))}`
}

export const formatContentPlaceholderEmailTemplate = (
  inputContent: string,
  placeholderEmails: { [key: string]: string }
) => {
  let errorKeys: Array<string> = []
  const contentFinal = Object.keys(placeholderEmails).reduce((content, key) => {
    if (!placeholderEmails[key] && inputContent.search(key) !== -1) {
      errorKeys.push(key)
    }

    return content.replaceAll(`{${key}}`, placeholderEmails[key])
  }, inputContent)

  return {
    content: contentFinal,
    errorKeys
  }
}

export const useConvertSalary = (): {
  convertOnlyNumberSalary: (labelValue: number) => number | string
  numberWithCommas: (value: number) => number | string
} => {
  return {
    convertOnlyNumberSalary: (labelValue: number) => {
      return Math.abs(Number(labelValue)) >= 1.0e9
        ? (Math.abs(Number(labelValue)) / 1.0e9)
            .toFixed(2)
            .replace(/(\.0+|0+)$/, '')
        : // Six Zeroes for Millions
        Math.abs(Number(labelValue)) >= 1.0e6
        ? (Math.abs(Number(labelValue)) / 1.0e6)
            .toFixed(2)
            .replace(/(\.0+|0+)$/, '')
        : Math.abs(Number(labelValue))
    },
    numberWithCommas: (value: number) => {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  }
}

export const getPlaceholderValueJobForEmailEditor = ({
  jobId = undefined,
  jobList = []
}: {
  jobId?: string
  jobList?: Array<ICandidateApplicant>
}) => {
  if (!jobId || jobList?.length === 0) return {} as ICandidateApplicant

  return jobList.filter((item) => String(item.jobId) === String(jobId))?.[0]
}
export const permittedFieldsManagement = (currentRole?: string[]) => {
  if (!currentRole) return false
  if (currentRole.includes(ROLE.Everyone)) return true
  else
    return [ROLE.Admin, ROLE.LimitedMember].some(
      (value) => currentRole.indexOf(value) >= 0
    )
}

export const reorderLinksList = (
  links: { [key: string]: Array<string> },
  maximumShowingLogo: number
) => {
  const orderingLogoLinks = [
    'behance',
    'facebook',
    'xing',
    'gitlab',
    'instagram',
    'linkedin',
    'canva',
    'github',
    'portfolio',
    'others'
  ]

  const resultShowing: Array<{ social_logo: string; link: string }> = []
  const resultHidden: Array<{ social_logo: string; link: string }> = []

  orderingLogoLinks.forEach((social) => {
    links[social]?.length > 0 &&
      Array.isArray(links[social]) &&
      links[social]?.forEach((link, index) => {
        if (resultShowing?.length < maximumShowingLogo) {
          resultShowing.push({ social_logo: social, link })
        } else {
          resultHidden.push({ social_logo: social, link })
        }
      })
  })
  return {
    showing: resultShowing,
    hidden: resultHidden
  }
}
const formatDistanceLocale = {
  lessThanXSeconds: '{{count}}s',
  xSeconds: '{{count}}s',
  halfAMinute: '30s',
  lessThanXMinutes: '{{count}}m',
  xMinutes: '{{count}}m',
  aboutXHours: '{{count}}h',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
  aboutXWeeks: '{{count}}w',
  xWeeks: '{{count}}w',
  aboutXMonths: '{{count}}m',
  xMonths: '{{count}}m',
  aboutXYears: '{{count}}y',
  xYears: '{{count}}y',
  overXYears: '{{count}}y',
  almostXYears: '{{count}}y'
}
export const shortEnLocale = {
  ...locale,
  formatDistance: (token: keyof typeof formatDistanceLocale, count: number) => {
    return formatDistanceLocale[token].replace('{{count}}', count.toString())
  }
}

export const checkPermissionForActions = ({
  user,
  currentRole,
  jobDetail,
  permission,
  ignoreAdmin = false,
  ignoreOwner = false,
  ignoreRecruiters = false
}: {
  user?: IUserInformation
  currentRole?: IRole
  jobDetail?: {
    jobRecruiters?: {
      id: string
      responsibility: string
      responsibilityDescription: string
      user: IMemberInfo
    }[]
    owner?: IMemberInfo
  }
  permission: boolean
  ignoreAdmin?: boolean
  ignoreOwner?: boolean
  ignoreRecruiters?: boolean
}) => {
  if (adminCanAction(currentRole?.code) && ignoreAdmin === false) {
    return true
  }

  if (permission) {
    return true
  }

  if (jobDetail?.owner?.id && user?.id && ignoreOwner === false) {
    if (String(jobDetail.owner.id) === String(user.id)) {
      return true
    }
  }

  let isEdited = false
  if (
    jobDetail?.jobRecruiters?.length &&
    user?.id &&
    ignoreRecruiters === false
  ) {
    for (let i = 0; i <= jobDetail?.jobRecruiters.length - 1; i++) {
      const recruiterItem = jobDetail?.jobRecruiters[i]
      if (String(recruiterItem?.user?.id) === String(user.id)) {
        isEdited = true
      }
    }
  }

  return isEdited
}

export const checkShowFeedbackInterview = ({
  type = 'button',
  user,
  currentRole,
  item,
  permission = false
}: {
  type: 'button' | 'page' | 'layout'
  user?: IUserInformation
  currentRole?: IRole
  item?: InterviewDetailType
  permission?: boolean
}) => {
  if (!user || !item) return false

  const checkUserIsOrganizerOrInHiringTeam = checkPermissionForActions({
    user,
    currentRole,
    jobDetail: {
      owner: item?.job?.owner,
      jobRecruiters: item?.job?.jobRecruiters
    },
    permission
  })
  const isUserInAssignees = !!item?.attendees?.find(
    (attendee) => String(attendee.id) === String(user.id)
  )
  const isCanAccess = checkUserIsOrganizerOrInHiringTeam || isUserInAssignees

  if (type === 'button') {
    if (isCanAccess) {
      return (
        !item?.currentUserFeedback?.id ||
        item?.currentUserFeedback?.status === 'draft'
      )
    }

    return false
  }

  if (type === 'page') return isCanAccess

  return item?.currentUserFeedback
}

export const getYearOld = (birthday: Date) => {
  return differenceInYears(new Date(), birthday)
}
