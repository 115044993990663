import { ROLE_CODE } from '../constants/role'

const adminAndMemberCanAction = (currentRole?: string) => {
  if (!currentRole) return false
  return [ROLE_CODE.Admin, ROLE_CODE.Member].includes(currentRole)
}

const adminCanAction = (currentRole?: string) => {
  if (!currentRole) return false
  return ROLE_CODE.Admin === currentRole
}

const memberCanAction = (currentRole?: string) => {
  if (!currentRole) return false
  return ROLE_CODE.Member === currentRole
}

const limitedMemberCanAction = (currentRole?: string) => {
  if (!currentRole) return false
  return ROLE_CODE.LimitedMember === currentRole
}

export {
  adminAndMemberCanAction,
  adminCanAction,
  limitedMemberCanAction,
  memberCanAction
}
