'use client'

import { cn } from '~/core/ui/utils'
import * as ScrollAreaWrapper from '@radix-ui/react-scroll-area'
import { cva } from 'class-variance-authority'
import { ReactNode, forwardRef, CSSProperties } from 'react'
import { RefObject } from '@fullcalendar/core/preact'

const scrollAreaRootVariants = cva(
  'flex touch-none select-none transition-colors',
  {
    variants: {
      orientation: {
        vertical: 'h-full w-2.5 border-l border-l-transparent p-[1px]',
        horizontal: 'h-2.5 border-t border-t-transparent p-[1px]'
      }
    },
    defaultVariants: {
      orientation: 'vertical'
    }
  }
)

interface ScrollAreaProps {
  children?: ReactNode
  className?: string | number
  viewportClassName?: string
  thumbClassName?: string
  scrollBarClassName?: string
  orientation?: 'vertical' | 'horizontal'
  scrollRef?: RefObject<HTMLDivElement>
  rootStyle?: CSSProperties
}

const ScrollArea = forwardRef<
  React.ElementRef<typeof ScrollAreaWrapper.Root>,
  ScrollAreaProps
>(
  (
    {
      children,
      orientation = 'vertical',
      className,
      viewportClassName,
      thumbClassName,
      scrollBarClassName,
      scrollRef,
      rootStyle = {}
    },
    ref
  ) => {
    return (
      <ScrollAreaWrapper.Root
        ref={ref}
        className={cn('relative overflow-hidden dark:bg-gray-900', className)}
        style={rootStyle}>
        <ScrollAreaWrapper.Viewport
          ref={scrollRef}
          className={viewportClassName || 'h-full w-full rounded-[inherit]'}>
          {children}
        </ScrollAreaWrapper.Viewport>

        <ScrollAreaWrapper.Scrollbar
          className={cn(
            scrollAreaRootVariants({ orientation }),
            scrollBarClassName
          )}
          orientation={orientation}>
          <ScrollAreaWrapper.Thumb
            className={cn(
              'relative rounded-full bg-gray-500',
              orientation === 'vertical' && 'flex-1',
              thumbClassName
            )}
          />
        </ScrollAreaWrapper.Scrollbar>

        <ScrollAreaWrapper.Corner />
      </ScrollAreaWrapper.Root>
    )
  }
)

ScrollArea.displayName = 'ScrollArea'

export { ScrollArea }
export type { ScrollAreaProps }
