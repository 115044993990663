import { gql } from 'urql'

const QueryCountryStates = gql<{
  countryStates: {
    collection: {
      id: string
      name: string
      fullName: string
      country: {
        name: string
      }
      status?: number
    }[]
    metadata: {
      totalCount: number
    }
  }
}>`
  query (
    $limit: Int
    $page: Int
    $search: String
    $stateOnly: Boolean
    $defaultShow: Boolean
    $countryId: Int
  ) {
    countryStates(
      limit: $limit
      page: $page
      search: $search
      stateOnly: $stateOnly
      defaultShow: $defaultShow
      countryId: $countryId
    ) {
      collection {
        id
        fullName
        name
        country {
          name
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export default QueryCountryStates
