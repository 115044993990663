import { TFunction } from 'i18next'
import { z } from 'zod'
import { regexEmailValidation } from '~/core/utilities/common'
import { schemaCustomField } from '../../settings/profile-fields/schema/custom-field-schema'
const MAX_FILE_SIZE = '10MB'
const ACCEPTED_FILE_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

const conversionFactors: { [unit: string]: number } = {
  KB: 1024,
  MB: 1024 ** 2,
  GB: 1024 ** 3,
  TB: 1024 ** 4
}
export const regexTagValidation =
  /^[\u0102-\u1EF9A-Za-z0-9!@#$%^&*()\-_=+[\]{};:'",.<>/?\\|]*$/g

const convertFileSizeToBytes = ({ size }: { size: string }) => {
  const sizeRegex = /^(\d+(?:\.\d+)?)\s*(KB|MB|GB|TB)$/i
  const match = size.match(sizeRegex)
  if (match) {
    const sizeInUnits = parseFloat(match[1])
    const unit = match[2].toUpperCase()

    const sizeInBytes = sizeInUnits * conversionFactors[unit]
    return sizeInBytes
  } else {
    throw new Error(`Invalid size format: ${size}`)
  }
}

export const schemaUpdateProfile = (t: TFunction) => {
  return z
    .object({
      fullName: z
        .string()
        .trim()
        .min(1, {
          message: `${t('form:requiredField')}`
        })
        .max(100, {
          message: `${t('form:field_max_number_required', { number: 100 })}`
        }),
      headline: z
        .string()
        .max(100, {
          message: `${t('form:field_max_number_required', { number: 100 })}`
        })
        .nullish()
        .optional(),
      email: z
        .array(z.string())
        .refine(
          (value) => {
            return value?.length ? regexEmailValidation.test(value[0]) : true
          },
          {
            message: `${t('form:invalid_email')}`
          }
        )
        .optional(),
      phoneNumber: z
        .array(
          z.string().max(15, {
            message: `${t('form:invalid_phone_number')}`
          })
        )
        .optional(),
      location: z.string().nullish().optional(),
      locationWithStateID: z
        .object({
          id: z.string().optional(),
          value: z.string(),
          supportingObj: z.object({
            name: z.string()
          })
        })
        .nullish()
        .optional(),
      links: z
        .object({
          value: z.string().optional(),
          _destroy: z.boolean().optional()
        })
        .optional(),
      skills: z.array(z.string()).optional(),
      currentSalary: z
        .number({
          invalid_type_error: `${t(
            'form:salary_should_only_contain_numerical_values'
          )}`
        })
        .int({
          message: `${t('form:decimals_are_not_allowed')}`
        })
        .nonnegative({
          message: `${t('form:salary_can_not_be_zero_or_negative')}`
        })
        .optional(),
      currentSalaryCurrency: z.string().nullish().optional(),
      expectedSalary: z
        .number({
          invalid_type_error: `${t(
            'form:salary_should_only_contain_numerical_values'
          )}`
        })
        .int({
          message: `${t('form:decimals_are_not_allowed')}`
        })
        .nonnegative({
          message: `${t('form:salary_can_not_be_zero_or_negative')}`
        })
        .optional(),
      expectedSalaryCurrency: z.string().nullish().optional(),
      nationality: z.string().nullish().optional(),
      sourced: z.string().nullish().optional(),
      willingToRelocate: z.boolean().optional(),
      noticeToPeriodDays: z.string().nullish().optional(),
      resumeFile: z
        .string({ required_error: `${t('form:requiredField')}` })
        .optional()
        .or(
          z
            .array(z.any())
            .refine(
              (files) =>
                files?.[0]?.size <=
                convertFileSizeToBytes({ size: MAX_FILE_SIZE }),
              `${t('form:invalid_upload_file', {
                maximumSizeFile: MAX_FILE_SIZE
              })}`
            )
            .refine(
              (files) => ACCEPTED_FILE_TYPES.includes(files?.[0]?.type),
              `${t('form:invalid_upload_file', {
                maximumSizeFile: MAX_FILE_SIZE
              })}`
            )
        ),
      tags: z.array(
        z.object({
          value: z.string(),
          label: z.string().optional(),
          supportingObj: z.object({
            name: z.string()
          })
        })
      ),
      editorLanguages: z
        .array(
          z.object({
            language: z
              .object({
                value: z.string(),
                supportingObj: z.object({ name: z.string() })
              })
              .nullish()
              .refine((obj) => !!obj?.value, {
                message: `${t('form:requiredField')}`
              }),
            proficiency: z
              .object({
                value: z.string().optional(),
                supportingObj: z.object({ name: z.string().optional() })
              })
              .nullish()
              .optional()
          })
        )
        .nullish()
        .optional(),
      editorLinks: z
        .record(z.string(), z.array(z.string()).optional())
        .nullish()
        .optional(),
      profileTalentPoolIds: z.array(z.string()).optional()
    })
    .and(schemaCustomField(t))
}
