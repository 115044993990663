import { gql } from 'urql'

const QueryJobsApplicableList = gql`
  query ($limit: Int!, $page: Int!, $search: String) {
    jobsApplicableList(limit: $limit, page: $page, search: $search) {
      collection {
        id
        title
        jobLocations {
          state
          country
        }
        department {
          name
        }
        status
        statusDescription
        company {
          id
          name
        }
      }

      metadata {
        totalCount
      }
    }
  }
`

export default QueryJobsApplicableList
